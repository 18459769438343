<!--
 * @Description: 创建服务详情
 * @Author: ChenXueLin
 * @Date: 2021-08-30 09:11:35
 * @LastEditTime: 2022-07-01 09:22:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="创建服务详情"
    :visible="addServiceDialog"
    width="450px"
    v-loading="loading"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="createDispatchDialog"
  >
    <el-form
      label-width="120px"
      ref="serviceForm"
      :model="serviceForm"
      :rules="serviceFormRules"
    >
      <el-form-item label="第一联系人类型" prop="isEmployee">
        <el-radio-group
          v-model="serviceForm.isEmployee"
          @change="handleTypeChange"
        >
          <el-radio label="0">客户</el-radio>
          <el-radio label="1">员工</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="第一联系人"
        prop="phoneCallName"
        v-if="
          serviceForm.isEmployee === '0' &&
            (basicInfo.taskSource == 3 || basicInfo.taskSource == 5)
        "
        :rules="{
          required: true,
          message: '请输入第一联系人姓名',
          trigger: ['blur', 'change'],
        }"
      >
        <el-input
          placeholder="第一联系人"
          v-model="serviceForm.phoneCallName"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="第一联系人"
        prop="phoneCallId"
        v-if="
          serviceForm.isEmployee === '0' &&
            !(basicInfo.taskSource == 3 || basicInfo.taskSource == 5)
        "
      >
        <e6-vr-select
          v-model="serviceForm.phoneCallId"
          :data="contactList"
          placeholder="第一联系人"
          title="第一联系人"
          :props="{
            id: 'contactId',
            label: 'contactNamePhone',
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item
        label="第一联系人"
        prop="phoneCallId"
        v-if="serviceForm.isEmployee === '1'"
      >
        <all-user-search
          v-model="serviceForm.phoneCallId"
          clear
          placeholder="第一联系人"
          title="第一联系人"
          :propsConfig="{
            id: 'employeeId',
            label: 'contactNamePhone',
          }"
          @change="handleEmployeeChange"
        >
        </all-user-search>
      </el-form-item>

      <el-form-item label="联系电话" prop="phoneNumber">
        <el-input
          aria-placeholder="联系电话"
          v-model="serviceForm.phoneNumber"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务类型" prop="firstServerCode">
        <div class="select-box">
          <e6-vr-select
            v-model="serviceForm.firstServerCode"
            :data="firstTypeList"
            :props="{
              id: 'codeId',
              label: 'codeName',
            }"
            placeholder="一级服务类型"
            title="一级服务类型"
            clearable
          ></e6-vr-select>
          <e6-vr-select
            v-model="serviceForm.secondServerCode"
            :data="secondTypeList"
            :props="{
              id: 'codeId',
              label: 'codeName',
            }"
            :loading="selectLoading"
            placeholder="二级服务类型"
            title="二级服务类型"
            clearable
          ></e6-vr-select>
        </div>
      </el-form-item>
      <el-form-item label="来去电类型" prop="phoneTypeCode">
        <e6-vr-select
          v-model="serviceForm.phoneTypeCode"
          :data="callTypeList"
          placeholder="来去电类型"
          title="来去电类型"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName',
          }"
        ></e6-vr-select>
      </el-form-item>

      <el-form-item label="联系时间" prop="serverTimeTimestamp">
        <el-date-picker
          v-model="serviceForm.serverTimeTimestamp"
          type="datetime"
          placeholder="选择日期"
          value-format="timestamp"
          popper-class="special"
          default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="服务内容" prop="serverDetails">
        <el-input
          placeholder="服务内容"
          type="textarea"
          v-model="serviceForm.serverDetails"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="是否下派"
        prop="isAssign"
        :rules="{
          required: true,
          message: '请选择是否下派',
          trigger: ['blur', 'change'],
        }"
      >
        <el-radio-group v-model="serviceForm.isAssign">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="未下派原因"
        prop="firstReason"
        :rules="
          serviceForm.isAssign
            ? {
                required: false,
              }
            : {
                required: true,
                message: '请选择未下派原因',
                trigger: ['blur', 'change'],
              }
        "
      >
        <div class="select-box">
          <e6-vr-select
            v-model="serviceForm.firstReason"
            :data="notAssignedReasonList"
            :props="{
              id: 'codeValue',
              label: 'codeName',
            }"
            placeholder="一级下派原因"
            title="一级下派原因"
            clearable
            :disabled="serviceForm.isAssign ? true : false"
            @change="changeReason"
            @clear="clearReason"
          ></e6-vr-select>
          <e6-vr-select
            v-model="serviceForm.secondReason"
            :data="secondReasonList"
            :props="{
              id: 'codeValue',
              label: 'codeName',
            }"
            :disabled="serviceForm.isAssign ? true : false"
            :loading="selectLoading"
            placeholder="二级下派原因"
            title="二级下派原因"
            clearable
          ></e6-vr-select>
        </div>
      </el-form-item>
      <el-form-item label="工单ID" prop="phoneCallName">
        {{ itemIdList.length ? itemIdList.join("、") : "全部任务项" }}
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit"> 确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { telAndMobileValid } from "@/utils/validate";
import allUserSearch from "@/components/allUserSearch";
import {
  createService,
  getContactList,
  getSecondType,
  getFirstType,
  findDownList,
} from "@/api";
import { printError } from "@/utils/util";

export default {
  name: "addServiceDetail",
  components: { allUserSearch },
  data() {
    return {
      selectLoading: false,
      loading: false,
      contactList: [], //客户联系人下拉框
      firstTypeList: [], //一级服务类型下拉框数据
      secondTypeList: [], //二级服务类型下拉框数据
      callTypeList: [], //来去电类型
      serviceForm: {
        phoneTypeCode: "4", //来去电类型
        isEmployee: "0",
        phoneType: "去电",
        phoneCallId: "", //联系人id
        phoneCallName: "", //联系人
        phoneNumber: "", //联系人电话
        serverTimeTimestamp: "", //联系时间
        serverDetails: "", //服务内容
        firstServerCode: "", //一级服务类型码值
        firstServer: "", //一级服务类型
        secondServerCode: "", //二级服务类型码值
        secondServer: "", //二级服务类型
        isAssign: 1,
        firstReason: "",
        secondReason: "",
      },
      serviceFormRules: {
        phoneCallId: [
          {
            required: true,
            message: "请选择第一联系人",
            trigger: ["blur", "change"],
          },
        ],
        phoneNumber: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"],
        }),
        serverTimeTimestamp: [
          {
            required: true,
            message: "请选择联系时间",
            trigger: ["blur", "change"],
          },
        ],
        phoneTypeCode: [
          {
            required: true,
            message: "请选择来去电类型",
            trigger: ["blur", "change"],
          },
        ],
        firstServerCode: [
          {
            required: true,
            message: "请选择一级服务类型",
            trigger: ["blur", "change"],
          },
        ],
        serverDetails: [
          {
            required: true,
            message: "请输入服务内容",
            trigger: ["blur", "change"],
          },
        ],
      },
      notAssignedReasonList: [], //一级下派原因
      secondReasonList: [], //二级下派原因
    };
  },
  props: [
    "basicInfo",
    "taskType",
    "addServiceDialog",
    "customerId",
    "customerName",
    "taskId",
    "contact",
    "taskNum",
    "itemIdList",
  ],
  mixins: [base],
  computed: {},
  watch: {
    // 监听一级分类选择更改
    "serviceForm.firstServerCode": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getSecondType(val);
        } else {
          this.firstTypeList = []; //一级服务类型下拉框数据
          this.secondTypeList = [];
          this.serviceForm.firstServerCode = "";
          this.serviceForm.firstServer = "";
          this.serviceForm.secondServerCode = "";
          this.serviceForm.secondServer = "";
        }
      },
    },
    // 监听联系人名称更改
    "serviceForm.phoneCallId": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          if (this.serviceForm.isEmployee === "0") {
            let concatInfo = this.contactList.find((item) => {
              return item.contactId == val;
            });
            if (concatInfo.phone1) {
              this.serviceForm.phoneNumber = concatInfo.phone1;
            }
            this.serviceForm.phoneCallName = concatInfo.corpContactName;
          }
        }
      },
    },
    addServiceDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.serviceForm.serverTimeTimestamp = Date.parse(new Date());
          this.initData();
        }
      },
    },
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.loading = true;
        let promiseList;
        if (this.basicInfo.taskSource == 3 || this.basicInfo.taskSource == 5) {
          promiseList = [
            findDownList(["electricityType", "notAssignedReason"]),
            getFirstType({ whether: 1 }),
          ];
          let [callTypeRes, firstTypeRes] = await Promise.all(promiseList);
          //来去电类型下拉框
          this.callTypeList = this.getFreezeResponse(callTypeRes, {
            path: "data.electricityType",
          });
          //一级下派原因
          this.notAssignedReasonList = this.getFreezeResponse(callTypeRes, {
            path: "data.notAssignedReason",
          });
          //问题一级类型
          this.firstTypeList = this.getFreezeResponse(firstTypeRes, {
            path: "data",
          });
          this.serviceForm.phoneTypeCode = "4";
          this.serviceForm.phoneType = "去电";
          this.serviceForm.phoneCallName = this.contact;
          this.serviceForm.phoneNumber = this.basicInfo.corpContactPhone;
        } else {
          promiseList = [
            getContactList({ id: this.customerId }),
            findDownList(["electricityType", "notAssignedReason"]),
            getFirstType({ whether: 1 }),
          ];
          let [contactRes, callTypeRes, firstTypeRes] = await Promise.all(
            promiseList
          );
          //一级下派原因
          this.notAssignedReasonList = this.getFreezeResponse(callTypeRes, {
            path: "data.notAssignedReason",
          });
          //获取联系人下拉框
          this.contactList = contactRes.data.length
            ? contactRes.data.map((item) => {
                if (item.phoneList && item.phoneList.length) {
                  item.contactNamePhone = `${
                    item.contactName
                  }(${item.phoneList.join(",")})`;
                }
                return item;
              })
            : [];
          //来去电类型下拉框
          this.callTypeList = this.getFreezeResponse(callTypeRes, {
            path: "data.electricityType",
          });
          //问题一级类型
          this.firstTypeList = this.getFreezeResponse(firstTypeRes, {
            path: "data",
          });
          this.serviceForm.phoneTypeCode = "4";
          this.serviceForm.phoneType = "去电";
          this.serviceForm.phoneCallName = this.contact;
          this.serviceForm.phoneCallId = this.contactList.length
            ? this.contactList.find((item) => {
                return item.contactName === this.contact;
              }).contactId
            : "";
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //切换员工
    handleEmployeeChange(val, node) {
      this.serviceForm.phoneNumber = node.phone ? node.phone : "";
      this.serviceForm.phoneCallName = node.employeeName;
    },
    changeReason(val, node) {
      this.serviceForm.secondReason = "";
      this.getSecondReason(node.codeId);
    },
    clearReason() {
      this.serviceForm.secondReason = "";
      this.secondReasonList = [];
    },
    //获取二级下派原因
    async getSecondReason(val) {
      try {
        this.selectLoading = true;
        let res = await findDownList([val]);
        this.secondReasonList = res.data[val];
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //获取二级服务类型
    async getSecondType() {
      try {
        this.selectLoading = true;
        let res = await getSecondType({
          firstServerCode: this.serviceForm.firstServerCode,
        });
        this.secondTypeList = this.getFreezeResponse(res, {
          path: "data",
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //来去电人类型切换
    handleTypeChange() {
      if (this.basicInfo.taskSource == 3 || this.basicInfo.taskSource == 5) {
        return;
      }
      this.serviceForm.phoneCallId = "";
      this.serviceForm.phoneNumber = "";
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.serviceForm.validate((valid) => {
        if (valid) {
          if (!this.serviceForm.secondServerCode) {
            this.$message.warning("请选择二级服务类型");
            return;
          }
          //来去电类型Name
          this.serviceForm.phoneType = this.filterSelect(
            this.callTypeList,
            "codeValue",
            this.serviceForm.phoneTypeCode
          ).codeName;
          //一级服务类型
          this.serviceForm.firstServer = this.filterSelect(
            this.firstTypeList,
            "codeId",
            this.serviceForm.firstServerCode
          ).codeName;
          //二级服务类型
          this.serviceForm.secondServer = this.filterSelect(
            this.secondTypeList,
            "codeId",
            this.serviceForm.secondServerCode
          ).codeName;
          //联系人名字
          // this.serviceForm.phoneCallName = this.filterSelect(
          //   this.contactList,
          //   "contactId",
          //   this.serviceForm.phoneCallId
          // ).contactName;
          //客户名称
          this.serviceForm.customerName = this.customerName;
          //客户Id
          this.serviceForm.customerId = this.customerId;
          this.createServiceReq();
        }
      });
    },
    //添加服务详情请求
    async createServiceReq() {
      try {
        this.loading = true;
        let res = await createService({
          ...this.serviceForm,
          taskId: this.taskId,
          taskNum: this.taskNum,
          itemIdList: this.itemIdList,
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("getTaskServiceReq");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.serviceForm = {
        phoneTypeCode: "4", //来去电类型
        phoneType: "去电",
        phoneCallId: "", //联系人
        phoneCallName: "", //联系人
        phoneNumber: "", //联系人电话
        serverTimeTimestamp: "", //联系时间
        serverDetails: "", //服务内容
        firstServerCode: "", //一级服务类型码值
        firstServer: "", //一级服务类型
        secondServerCode: "", //二级服务类型码值
        secondServer: "", //二级服务类型
        isAssign: 1,
        firstReason: "",
        secondReason: "",
      };
      this.$refs.serviceForm.resetFields();
      this.$emit("handleClose", "addServiceDialog");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding-top: 20px;
  }
  .el-input {
    width: 260px;
  }
  .el-textarea {
    width: 260px;
  }
  .city-div {
    width: 260px !important;
    .city-input {
      width: 260px !important;
    }
  }
}
/deep/.select-box {
  display: flex;
  .e6-vr-select {
    margin-right: 10px;
    .el-input {
      width: 125px;
    }
  }
}
</style>
