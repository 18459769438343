var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":"创建服务详情","visible":_vm.addServiceDialog,"width":"450px","close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"before-close":_vm.handleClose,"custom-class":"createDispatchDialog"}},[_c('el-form',{ref:"serviceForm",attrs:{"label-width":"120px","model":_vm.serviceForm,"rules":_vm.serviceFormRules}},[_c('el-form-item',{attrs:{"label":"第一联系人类型","prop":"isEmployee"}},[_c('el-radio-group',{on:{"change":_vm.handleTypeChange},model:{value:(_vm.serviceForm.isEmployee),callback:function ($$v) {_vm.$set(_vm.serviceForm, "isEmployee", $$v)},expression:"serviceForm.isEmployee"}},[_c('el-radio',{attrs:{"label":"0"}},[_vm._v("客户")]),_vm._v(" "),_c('el-radio',{attrs:{"label":"1"}},[_vm._v("员工")])],1)],1),_vm._v(" "),(
        _vm.serviceForm.isEmployee === '0' &&
          (_vm.basicInfo.taskSource == 3 || _vm.basicInfo.taskSource == 5)
      )?_c('el-form-item',{attrs:{"label":"第一联系人","prop":"phoneCallName","rules":{
        required: true,
        message: '请输入第一联系人姓名',
        trigger: ['blur', 'change'],
      }}},[_c('el-input',{attrs:{"placeholder":"第一联系人"},model:{value:(_vm.serviceForm.phoneCallName),callback:function ($$v) {_vm.$set(_vm.serviceForm, "phoneCallName", $$v)},expression:"serviceForm.phoneCallName"}})],1):_vm._e(),_vm._v(" "),(
        _vm.serviceForm.isEmployee === '0' &&
          !(_vm.basicInfo.taskSource == 3 || _vm.basicInfo.taskSource == 5)
      )?_c('el-form-item',{attrs:{"label":"第一联系人","prop":"phoneCallId"}},[_c('e6-vr-select',{attrs:{"data":_vm.contactList,"placeholder":"第一联系人","title":"第一联系人","props":{
          id: 'contactId',
          label: 'contactNamePhone',
        },"clearable":""},model:{value:(_vm.serviceForm.phoneCallId),callback:function ($$v) {_vm.$set(_vm.serviceForm, "phoneCallId", $$v)},expression:"serviceForm.phoneCallId"}})],1):_vm._e(),_vm._v(" "),(_vm.serviceForm.isEmployee === '1')?_c('el-form-item',{attrs:{"label":"第一联系人","prop":"phoneCallId"}},[_c('all-user-search',{attrs:{"clear":"","placeholder":"第一联系人","title":"第一联系人","propsConfig":{
          id: 'employeeId',
          label: 'contactNamePhone',
        }},on:{"change":_vm.handleEmployeeChange},model:{value:(_vm.serviceForm.phoneCallId),callback:function ($$v) {_vm.$set(_vm.serviceForm, "phoneCallId", $$v)},expression:"serviceForm.phoneCallId"}})],1):_vm._e(),_vm._v(" "),_c('el-form-item',{attrs:{"label":"联系电话","prop":"phoneNumber"}},[_c('el-input',{attrs:{"aria-placeholder":"联系电话"},model:{value:(_vm.serviceForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.serviceForm, "phoneNumber", $$v)},expression:"serviceForm.phoneNumber"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"服务类型","prop":"firstServerCode"}},[_c('div',{staticClass:"select-box"},[_c('e6-vr-select',{attrs:{"data":_vm.firstTypeList,"props":{
            id: 'codeId',
            label: 'codeName',
          },"placeholder":"一级服务类型","title":"一级服务类型","clearable":""},model:{value:(_vm.serviceForm.firstServerCode),callback:function ($$v) {_vm.$set(_vm.serviceForm, "firstServerCode", $$v)},expression:"serviceForm.firstServerCode"}}),_vm._v(" "),_c('e6-vr-select',{attrs:{"data":_vm.secondTypeList,"props":{
            id: 'codeId',
            label: 'codeName',
          },"loading":_vm.selectLoading,"placeholder":"二级服务类型","title":"二级服务类型","clearable":""},model:{value:(_vm.serviceForm.secondServerCode),callback:function ($$v) {_vm.$set(_vm.serviceForm, "secondServerCode", $$v)},expression:"serviceForm.secondServerCode"}})],1)]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"来去电类型","prop":"phoneTypeCode"}},[_c('e6-vr-select',{attrs:{"data":_vm.callTypeList,"placeholder":"来去电类型","title":"来去电类型","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName',
        }},model:{value:(_vm.serviceForm.phoneTypeCode),callback:function ($$v) {_vm.$set(_vm.serviceForm, "phoneTypeCode", $$v)},expression:"serviceForm.phoneTypeCode"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"联系时间","prop":"serverTimeTimestamp"}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择日期","value-format":"timestamp","popper-class":"special","default-time":"['00:00:00', '23:59:59']"},model:{value:(_vm.serviceForm.serverTimeTimestamp),callback:function ($$v) {_vm.$set(_vm.serviceForm, "serverTimeTimestamp", $$v)},expression:"serviceForm.serverTimeTimestamp"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"服务内容","prop":"serverDetails"}},[_c('el-input',{attrs:{"placeholder":"服务内容","type":"textarea"},model:{value:(_vm.serviceForm.serverDetails),callback:function ($$v) {_vm.$set(_vm.serviceForm, "serverDetails", $$v)},expression:"serviceForm.serverDetails"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"是否下派","prop":"isAssign","rules":{
        required: true,
        message: '请选择是否下派',
        trigger: ['blur', 'change'],
      }}},[_c('el-radio-group',{model:{value:(_vm.serviceForm.isAssign),callback:function ($$v) {_vm.$set(_vm.serviceForm, "isAssign", $$v)},expression:"serviceForm.isAssign"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("是")]),_vm._v(" "),_c('el-radio',{attrs:{"label":0}},[_vm._v("否")])],1)],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"未下派原因","prop":"firstReason","rules":_vm.serviceForm.isAssign
          ? {
              required: false,
            }
          : {
              required: true,
              message: '请选择未下派原因',
              trigger: ['blur', 'change'],
            }}},[_c('div',{staticClass:"select-box"},[_c('e6-vr-select',{attrs:{"data":_vm.notAssignedReasonList,"props":{
            id: 'codeValue',
            label: 'codeName',
          },"placeholder":"一级下派原因","title":"一级下派原因","clearable":"","disabled":_vm.serviceForm.isAssign ? true : false},on:{"change":_vm.changeReason,"clear":_vm.clearReason},model:{value:(_vm.serviceForm.firstReason),callback:function ($$v) {_vm.$set(_vm.serviceForm, "firstReason", $$v)},expression:"serviceForm.firstReason"}}),_vm._v(" "),_c('e6-vr-select',{attrs:{"data":_vm.secondReasonList,"props":{
            id: 'codeValue',
            label: 'codeName',
          },"disabled":_vm.serviceForm.isAssign ? true : false,"loading":_vm.selectLoading,"placeholder":"二级下派原因","title":"二级下派原因","clearable":""},model:{value:(_vm.serviceForm.secondReason),callback:function ($$v) {_vm.$set(_vm.serviceForm, "secondReason", $$v)},expression:"serviceForm.secondReason"}})],1)]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"工单ID","prop":"phoneCallName"}},[_vm._v("\n      "+_vm._s(_vm.itemIdList.length ? _vm.itemIdList.join("、") : "全部任务项")+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }